import './App.css';
import {useState} from 'react';
import axios from 'axios'

function App() {


  const [loading, setLoading] = useState(false);
  const [data1, setData1] = useState([]);
  const [keyword, setKeyword] = useState('');
  async function getUser() {
    try {
      setLoading(true);
      const response = await axios.get(`https://completion.amazon.com/api/2017/suggestions?limit=11&prefix=${keyword}&suggestion-type=WIDGET&suggestion-type=KEYWORD&page-type=Gateway&alias=aps&site-variant=desktop&version=3&event=onKeyPress&wc=&lop=en_US&fb=1&plain-mid=1`);
      setData1(response.data.suggestions);
      if (data1){
        console.log(data1)
      }
      console.log(keyword)
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
    
  }
  return (
    <>
    <div className="App">
      <h1>Amazon Longtail Keyword Finder</h1>
      <div className="keytitle">Enter Product Seed Keyword</div>
      <input onChange={key => {setKeyword(encodeURIComponent(key.target.value))}} placeholder="man wallet" type="text" />
      <button onClick={getUser}>Find</button>
      {loading && <div className="loading">Loading...</div>}
     <table className="container">
     
      { data1 &&  data1.map(data => 
      <tr>
        <td className="arrresult">{data.value}</td>
        </tr>
      )   }
      
      </table>
    </div>
    </>
  );
}

export default App;
